@import './../../../node_modules/flatpickr/dist/flatpickr.css';

.flatpickr-calendar {
  .flatpickr-current-month {
    .numInputWrapper {
      display: none;
    }
  }
}
.flatpickr-day.selected {
  background: #dc0177;
  border-color: #dc0177;
}
