@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import './styles/reset.scss';
@import './styles/responsive.scss';
@import './styles/flatpickr';
@import '~primeicons/primeicons.css';

html,
* {
  box-sizing: border-box;
  outline: 0;
}

body {
  font-family: var(--font-family);
  font-size: 1rem;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 300px;
  position: relative;
  color: #333;
  line-height: 1.4;

  @include mobile {}
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("https://img.dentaldepartures.com/flags.png") !important;
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url("https://img.dentaldepartures.com/flags@2x.png") !important; } 
}



a {
  text-decoration: none;
  color: #333;

}
#zsiq_float {
  display: none;
}
