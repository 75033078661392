@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import './../../../node_modules/flatpickr/dist/flatpickr.css';
@import '~primeicons/primeicons.css';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.desktop-only {
  display: block;
}

td.desktop-only, th.desktop-only {
  display: table-cell;
}

.mobile-only {
  display: none;
}

@media (max-width: 1200px) {
  .desktop-only, td.desktop-only, th.desktop-only {
    display: none;
  }

  td.mobile-only, th.mobile-only {
    display: table-cell;
  }

  span.mobile-only {
    display: inline-block;
  }

  .mobile-only {
    display: block;
  }
}
.flatpickr-calendar .flatpickr-current-month .numInputWrapper {
  display: none;
}

.flatpickr-day.selected {
  background: #dc0177;
  border-color: #dc0177;
}

html,
* {
  box-sizing: border-box;
  outline: 0;
}

body {
  font-family: var(--font-family);
  font-size: 1rem;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 300px;
  position: relative;
  color: #333;
  line-height: 1.4;
}
.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("https://img.dentaldepartures.com/flags.png") !important;
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("https://img.dentaldepartures.com/flags@2x.png") !important;
  }
}
a {
  text-decoration: none;
  color: #333;
}

#zsiq_float {
  display: none;
}