/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-main {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1300px) {
  .container-main {
    max-width: 1760px;
    padding-left: 80px;
    padding-right: 80px;
  }
  .container-main .container-main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .container-body {
    width: 100%;
    padding-left: 84px;
    padding-right: 84px;
  }
  .container-body .container-body {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 100%;
  float: left;
  margin-bottom: 60px;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 50%;
    float: left;
  }

  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 25%;
    float: left;
  }

  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }

  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */
.desktop-only {
  display: block;
}

td.desktop-only, th.desktop-only {
  display: table-cell;
}

.mobile-only {
  display: none;
}

@media (max-width: 1200px) {
  .desktop-only, td.desktop-only, th.desktop-only {
    display: none;
  }

  td.mobile-only, th.mobile-only {
    display: table-cell;
  }

  span.mobile-only {
    display: inline-block;
  }

  .mobile-only {
    display: block;
  }
}
:root {
  --primary-color: #323a4d;
  --primary-border-color: #323a4d;
  --primary-color-text: #ffffff;
  --primary-hover-color: #2a3141;
  --primary-hover-border-color: #2a3141;
  --primary-focus-color: 36, 192, 211;
  --primary-focus-shadow: 36, 192, 211;
  --secondary-opacity-color: #e7f3f5;
  --text-color: #3f3f46;
  --text-color-secondary: #71717a;
  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: #f4f4f5;
  --surface-d: #e5e7eb;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --font-family: "Manrope", Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f4f4f5;
  --surface-200: #e4e4e7;
  --surface-300: #d4d4d8;
  --surface-400: #a1a1aa;
  --surface-500: #71717a;
  --surface-600: #52525b;
  --surface-700: #3f3f46;
  --surface-800: #27272a;
  --surface-900: #18181b;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 0.375rem;
  --surface-ground: #fafafa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #e5e7eb;
  --surface-hover: #f4f4f5;
  --focus-ring: 0 0 0 1px var(--primary-hover-color);
  --maskbg: rgba(0, 0, 0, 0.4);
  --error-color: #de5959;
  color-scheme: light;
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: normal;
}

.p-component-overlay {
  transition-duration: 0.2s;
  background-color: #ffffffd4;
  border-radius: 7px;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: #e24c4c;
}

.p-text-secondary {
  color: #71717a;
}

.pi {
  font-size: 13px;
}

.p-link {
  font-size: 13px;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 5px;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}
.main-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #171616;
}

p,
.p-scrollpanel-content p,
.p-accordion-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 150%;
  color: #6A6969;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.text-center {
  text-align: center;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.75rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.375rem 0.75rem;
  gap: 0.5rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #d4d4d8;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.375rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  color: #3f3f46;
  padding: 0;
  margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.375rem 0.75rem;
  background: #eef2ff;
  color: #312e81;
  border-radius: 5px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #de5959;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.25rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: transparent;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 2.5rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
  color: #71717a;
  right: 0.75rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
  color: #71717a;
  right: 3.75rem;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #de5959;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #3f3f46;
  border: 1px solid #d4d4d8;
  border-radius: 5px;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #e5e7eb;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #3f3f46;
  transition: none;
  font-weight: 600;
  padding: 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: var(--primary-color);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}

.p-datepicker table {
  font-size: 13px;
  margin: 0.5rem 0;
}

.p-datepicker table th {
  padding: 0.5rem;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0.5rem;
}

.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: none;
  border: 1px solid transparent;
}

.p-datepicker table td > span.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-datepicker table td.p-datepicker-today > span {
  background: #d4d4d8;
  color: #3f3f46;
  border-color: transparent;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #f3f4f6;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid #f3f4f6;
  padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: none;
  border-radius: 5px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: none;
  border-radius: 5px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #f3f4f6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #f4f4f5;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #f4f4f5;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}

p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #71717a;
  right: 0.75rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #71717a;
  right: 3.75rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
.p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  transition: none;
  border-radius: 5px;
}

.p-cascadeselect:not(.p-disabled):hover {
  border-color: #d4d4d8;
}

.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 16px 25px;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #71717a;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #71717a;
  width: 3rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.25rem 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.75rem 1rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #de5959;
}

.p-input-filled .p-cascadeselect {
  background: #fafafa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #fafafa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
  padding-right: 0.75rem;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
  color: #71717a;
  right: 3rem;
}

.p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
  box-shadow: none;
  border-radius: 0;
  padding: 0.25rem 0 0.25rem 0.5rem;
}

.p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
  transform: rotate(90deg);
}

.p-checkbox {
  width: 19px;
  height: 19px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #d4d4d8;
  background: #ffffff;
  width: 19px;
  height: 19px;
  color: #3f3f46;
  border-radius: 5px;
  transition: none;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 10px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #d4d4d8;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #de5959;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #fafafa;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--primary-color);
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #fafafa;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--primary-color);
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.375rem 0.75rem;
  gap: 0.5rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #d4d4d8;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.375rem 0.75rem;
  background: #eef2ff;
  color: #312e81;
  border-radius: 5px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.375rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  color: #3f3f46;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #de5959;
}

p-chips.p-chips-clearable .p-inputtext {
  padding-right: 1.75rem;
}

p-chips.p-chips-clearable .p-chips-clear-icon {
  color: #71717a;
  right: 0.75rem;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #27272a;
  border: 1px solid #18181b;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  transition: none;
  border-radius: 5px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #d4d4d8;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 14.5px 19px;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #71717a;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #71717a;
  width: 3rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #71717a;
  right: 3rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: #3f3f46;
  background: #fafafa;
  margin: 0;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.25rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #de5959;
}

.p-input-filled .p-dropdown {
  background: #fafafa;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #fafafa;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
  background: #fafafa;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #71717a;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #71717a;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #71717a;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0.25rem 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #3f3f46;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.75rem 1rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #3f3f46;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #3f3f46;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #3f3f46;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #3f3f46;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--primary-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--primary-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: var(--primary-color);
}

.p-inputgroup-addon {
  background: #fafafa;
  color: #71717a;
  border-top: 1px solid #d4d4d8;
  border-left: 1px solid #d4d4d8;
  border-bottom: 1px solid #d4d4d8;
  padding: 16px 25px;
  min-width: 3rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid #d4d4d8;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 3rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #de5959;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2.5rem;
}

p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: #71717a;
  right: 0.75rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #de5959;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2.5rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: #71717a;
  right: 0.75rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
  right: 3.75rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
  right: 3.75rem;
}

p-inputnumber .p-inputnumber {
  position: relative;
}
p-inputnumber input {
  width: 100%;
  padding-left: 15px;
  padding-right: 35px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
p-inputnumber .p-inputnumber-button-group {
  position: absolute;
  right: 0;
  height: 100%;
}
p-inputnumber .p-inputnumber-button-up,
p-inputnumber .p-inputnumber-button-down {
  background-color: transparent;
  color: #000000;
}
p-inputnumber button {
  border-color: transparent !important;
  width: 2rem !important;
}
p-inputnumber button:hover {
  background-color: #DDDDDD !important;
  opacity: 0.9;
}
p-inputnumber button .pi-angle-up::before {
  content: "\ea77";
  font-family: "remixicon" !important;
  color: #71717a;
}
p-inputnumber button .pi-angle-down::before {
  content: "\ea4d";
  font-family: "remixicon" !important;
  color: #71717a;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: #d4d4d8;
  transition: none;
  border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #bebec4;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-color);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--primary-hover-color);
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #de5959;
}

.p-inputtext {
  font-family: "Museo Sans", Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  color: #3f3f46;
  background: #ffffff;
  padding: 16px 25px;
  border: 1px solid #d4d4d8;
  transition: none;
  appearance: none;
  border-radius: 5px;
  box-shadow: 1px 0 5px transparent;
}
@media (max-width: 1200px) {
  .p-inputtext {
    font-size: 16px;
  }
}

.p-inputtext:enabled:hover {
  border-color: #d4d4d8;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #E5E5E5;
  box-shadow: 1px 0 5px transparent;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #de5959;
  box-shadow: 0 0 0 1px var(--error-color);
}
.p-inputtext.ng-dirty.ng-invalid:focus {
  box-shadow: 0 0 0 1px var(--error-color);
  border-color: var(--error-color);
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: #71717a;
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #de5959;
}

.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: #71717a;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #71717a;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

::-webkit-input-placeholder {
  color: #B6B6B6;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.7px;
}

:-moz-placeholder {
  color: #B6B6B6;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.7px;
}

::-moz-placeholder {
  color: #B6B6B6;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.7px;
}

:-ms-input-placeholder {
  color: #B6B6B6;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.7px;
}

.p-input-filled .p-inputtext {
  background-color: #fafafa;
}

.p-input-filled .p-inputtext:enabled:hover {
  background-color: #fafafa;
}

.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-listbox {
  background: #ffffff;
  color: #3f3f46;
  border: 1px solid #d4d4d8;
  border-radius: 5px;
}

.p-listbox .p-listbox-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: #3f3f46;
  background: #fafafa;
  margin: 0;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list {
  padding: 0.25rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #3f3f46;
  transition: none;
  border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: transparent;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: #f4f4f5;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #de5959;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  transition: none;
  border-radius: 5px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #d4d4d8;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-multiselect .p-multiselect-label {
  padding: 16px 25px;
  transition: none;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #71717a;
}

.p-multiselect.p-multiselect-chip .p-multiselect-label {
  gap: 0.5rem;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  background: #eef2ff;
  color: #312e81;
  border-radius: 5px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #71717a;
  width: 3rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: #3f3f46;
  background: #fafafa;
  margin: 0;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.25rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #de5959;
}

.p-input-filled .p-multiselect {
  background: #fafafa;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #fafafa;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.75rem;
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: #71717a;
  right: 3rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #de5959;
}

.p-password-panel {
  padding: 1.25rem;
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #e5e7eb;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #ef4444;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #f59e0b;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #22c55e;
}

p-password.p-password-clearable .p-password-input {
  padding-right: 2.5rem;
}

p-password.p-password-clearable .p-password-clear-icon {
  color: #71717a;
  right: 0.75rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 4.25rem;
}

p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: #71717a;
  right: 2.5rem;
}

.p-radiobutton {
  width: 16px;
  height: 16px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #d4d4d8;
  background: #ffffff;
  width: 16px;
  height: 16px;
  color: #3f3f46;
  border-radius: 50%;
  transition: none;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #d4d4d8;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 6px;
  height: 6px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #de5959;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #fafafa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #fafafa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--primary-color);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--primary-color);
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #3f3f46;
  margin-left: 0.5rem;
  transition: none;
  font-size: 1.143rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
  color: #ef4444;
}

.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: var(--primary-color);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: var(--primary-color);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #dc2626;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  color: #3f3f46;
  transition: none;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #71717a;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f4f4f5;
  border-color: #d4d4d8;
  color: #3f3f46;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #71717a;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #de5959;
}

.p-slider {
  background: #e5e7eb;
  border: 0 none;
  border-radius: 5px;
}

.p-slider.p-slider-horizontal {
  height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
  width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  transition: none;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-slider .p-slider-range {
  background: var(--primary-color);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: none, left 0.2s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: none, bottom 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  color: #3f3f46;
  transition: none;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #71717a;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f4f4f5;
  border-color: #d4d4d8;
  color: #3f3f46;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #71717a;
}

.p-togglebutton.p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover {
  background: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #de5959;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  transition: none;
  border-radius: 5px;
}

.p-treeselect:not(.p-disabled):hover {
  border-color: #d4d4d8;
}

.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  border-color: var(--primary-color);
}

.p-treeselect .p-treeselect-label {
  padding: 16px 25px;
  transition: none;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #71717a;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #e5e7eb;
  color: #3f3f46;
  border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #71717a;
  width: 3rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #de5959;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.375rem 0.75rem;
}

.p-treeselect-panel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-treeselect-panel .p-treeselect-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: #3f3f46;
  background: #fafafa;
  margin: 0;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.75rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #fafafa;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #fafafa;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: 1.75rem;
}

p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: #71717a;
  right: 3rem;
}

.p-button {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 13px 1rem;
  font-size: 16px;
  transition: none;
  border-radius: 5px;
  font-weight: 300;
}
.p-button a {
  text-decoration: none;
}
.p-button a:hover {
  text-decoration: none;
}

a button:hover {
  text-decoration: none;
}

.p-button:enabled:hover {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-border-color);
}

.p-button:enabled:active {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-border-color);
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 8px;
  border: 1px solid #171616;
  color: #000;
}

.p-button.p-button-label-zero .p-button-label {
  display: none;
}

.p-button.p-button-outlined:enabled:hover {
  background: rgba(79, 70, 229, 0.04);
  color: var(--primary-color);
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
  background: rgba(79, 70, 229, 0.16);
  color: var(--primary-color);
  border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
  color: #71717a;
  border-color: #71717a;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #f4f4f5;
  color: #71717a;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #e5e7eb;
  color: #71717a;
}

.p-button.p-button-text {
  background-color: transparent;
  color: var(--primary-color);
  border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(79, 70, 229, 0.04);
  color: var(--primary-color);
  border-color: transparent;
}

.p-button.p-button-text:enabled:active {
  background: rgba(79, 70, 229, 0.16);
  color: var(--primary-color);
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: #71717a;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #f4f4f5;
  color: #71717a;
}

.p-button.p-button-text.p-button-plain:enabled:active {
  background: #e5e7eb;
  color: #71717a;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-button .p-button-label {
  transition-duration: 0.2s;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: var(--primary-color);
  background-color: #ffffff;
}

.p-button.p-button-raised {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}

.p-button.p-button-xs {
  font-size: 9px;
  padding: 5px;
}

.p-button.p-button-xs .p-button-icon {
  font-size: 9px;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.875rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 1.25rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-icon-only {
  width: 3rem;
}

.p-fluid .p-buttonset {
  display: flex;
}

.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #64748b;
  border: 1px solid #DDDDDD;
}

.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #475569;
  color: #ffffff;
  border-color: #475569;
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #c0c7d2;
}

.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #475569;
  color: #ffffff;
  border-color: #475569;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #222222;
  border: 1px solid;
  border-color: #222222;
  font-weight: 500;
  line-height: 20px;
  border-radius: 11.407px;
  border: 0.951px solid #DDD;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(100, 116, 139, 0.04);
  color: #222222;
  border: 0.951px solid #DDD;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(100, 116, 139, 0.16);
  color: #64748b;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #64748b;
  border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(100, 116, 139, 0.04);
  border-color: transparent;
  color: #64748b;
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(100, 116, 139, 0.16);
  border-color: transparent;
  color: #64748b;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #3b82f6;
  border: 1px solid #3b82f6;
}

.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #2563eb;
  color: #ffffff;
  border-color: #2563eb;
}

.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #b1cdfb;
}

.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #2563eb;
  color: #ffffff;
  border-color: #2563eb;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #3b82f6;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(59, 130, 246, 0.04);
  color: #3b82f6;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(59, 130, 246, 0.16);
  color: #3b82f6;
  border: 1px solid;
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #3b82f6;
  border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(59, 130, 246, 0.04);
  border-color: transparent;
  color: #3b82f6;
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(59, 130, 246, 0.16);
  border-color: transparent;
  color: #3b82f6;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #22c55e;
  border: 1px solid #22c55e;
}

.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #16a34a;
  color: #ffffff;
  border-color: #16a34a;
}

.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #a0efbd;
}

.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #16a34a;
  color: #ffffff;
  border-color: #16a34a;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #22c55e;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(34, 197, 94, 0.04);
  color: #22c55e;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(34, 197, 94, 0.16);
  color: #22c55e;
  border: 1px solid;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #22c55e;
  border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(34, 197, 94, 0.04);
  border-color: transparent;
  color: #22c55e;
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(34, 197, 94, 0.16);
  border-color: transparent;
  color: #22c55e;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #ffffff;
  background: #f59e0b;
  border: 1px solid #f59e0b;
}

.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #d97706;
  color: #ffffff;
  border-color: #d97706;
}

.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fbd89d;
}

.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #d97706;
  color: #ffffff;
  border-color: #d97706;
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f59e0b;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(245, 158, 11, 0.04);
  color: #f59e0b;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(245, 158, 11, 0.16);
  color: #f59e0b;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #f59e0b;
  border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(245, 158, 11, 0.04);
  border-color: transparent;
  color: #f59e0b;
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(245, 158, 11, 0.16);
  border-color: transparent;
  color: #f59e0b;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #a855f7;
  border: 1px solid #a855f7;
}

.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #9333ea;
  color: #ffffff;
  border-color: #9333ea;
}

.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #dcbbfc;
}

.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #9333ea;
  color: #ffffff;
  border-color: #9333ea;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(168, 85, 247, 0.04);
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(168, 85, 247, 0.16);
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #a855f7;
  border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(168, 85, 247, 0.04);
  border-color: transparent;
  color: #a855f7;
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(168, 85, 247, 0.16);
  border-color: transparent;
  color: #a855f7;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #ef4444;
  border: 1px solid #ef4444;
}

.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #dc2626;
  color: #ffffff;
  border-color: #dc2626;
}

.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #f9b4b4;
}

.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #dc2626;
  color: #ffffff;
  border-color: #dc2626;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ef4444;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(239, 68, 68, 0.04);
  color: #ef4444;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(239, 68, 68, 0.16);
  color: #ef4444;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #ef4444;
  border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(239, 68, 68, 0.04);
  border-color: transparent;
  color: #ef4444;
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(239, 68, 68, 0.16);
  border-color: transparent;
  color: #ef4444;
}

.p-button.p-button-link {
  color: var(--primary-color);
  background: transparent;
  border: transparent;
}

.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: var(--primary-color);
  border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem var(--primary-hover-color);
  border-color: transparent;
}

.p-button.p-button-link:enabled:active {
  background: transparent;
  color: var(--primary-color);
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #3f3f46;
  color: #fff;
}

.p-speeddial-action:hover {
  background: #27272a;
  color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}

.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-splitbutton {
  border-radius: 5px;
}

.p-splitbutton.p-button-outlined > .p-button {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(79, 70, 229, 0.04);
  color: var(--primary-color);
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(79, 70, 229, 0.16);
  color: var(--primary-color);
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #71717a;
  border-color: #71717a;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #f4f4f5;
  color: #71717a;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #e5e7eb;
  color: #71717a;
}

.p-splitbutton.p-button-text > .p-button {
  background-color: transparent;
  color: var(--primary-color);
  border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(79, 70, 229, 0.04);
  color: var(--primary-color);
  border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(79, 70, 229, 0.16);
  color: var(--primary-color);
  border-color: transparent;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #71717a;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #f4f4f5;
  color: #71717a;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #e5e7eb;
  color: #71717a;
}

.p-splitbutton.p-button-raised {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}

.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}

.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.875rem;
  padding: 0.65625rem 0.875rem;
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}

.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.25rem;
  padding: 0.9375rem 1.25rem;
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: transparent;
  color: #64748b;
  border: 1px solid;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(100, 116, 139, 0.04);
  color: #64748b;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(100, 116, 139, 0.16);
  color: #64748b;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: transparent;
  color: #64748b;
  border-color: transparent;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(100, 116, 139, 0.04);
  border-color: transparent;
  color: #64748b;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(100, 116, 139, 0.16);
  border-color: transparent;
  color: #64748b;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: transparent;
  color: #3b82f6;
  border: 1px solid;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(59, 130, 246, 0.04);
  color: #3b82f6;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(59, 130, 246, 0.16);
  color: #3b82f6;
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: transparent;
  color: #3b82f6;
  border-color: transparent;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(59, 130, 246, 0.04);
  border-color: transparent;
  color: #3b82f6;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(59, 130, 246, 0.16);
  border-color: transparent;
  color: #3b82f6;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: transparent;
  color: #22c55e;
  border: 1px solid;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(34, 197, 94, 0.04);
  color: #22c55e;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(34, 197, 94, 0.16);
  color: #22c55e;
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: transparent;
  color: #22c55e;
  border-color: transparent;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(34, 197, 94, 0.04);
  border-color: transparent;
  color: #22c55e;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(34, 197, 94, 0.16);
  border-color: transparent;
  color: #22c55e;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: transparent;
  color: #f59e0b;
  border: 1px solid;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(245, 158, 11, 0.04);
  color: #f59e0b;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(245, 158, 11, 0.16);
  color: #f59e0b;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: transparent;
  color: #f59e0b;
  border-color: transparent;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(245, 158, 11, 0.04);
  border-color: transparent;
  color: #f59e0b;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(245, 158, 11, 0.16);
  border-color: transparent;
  color: #f59e0b;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: transparent;
  color: #a855f7;
  border: 1px solid;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(168, 85, 247, 0.04);
  color: #a855f7;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(168, 85, 247, 0.16);
  color: #a855f7;
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: transparent;
  color: #a855f7;
  border-color: transparent;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(168, 85, 247, 0.04);
  border-color: transparent;
  color: #a855f7;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(168, 85, 247, 0.16);
  border-color: transparent;
  color: #a855f7;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: transparent;
  color: #ef4444;
  border: 1px solid;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(239, 68, 68, 0.04);
  color: #ef4444;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(239, 68, 68, 0.16);
  color: #ef4444;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: transparent;
  color: #ef4444;
  border-color: transparent;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(239, 68, 68, 0.04);
  border-color: transparent;
  color: #ef4444;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(239, 68, 68, 0.16);
  border-color: transparent;
  color: #ef4444;
}

.swiper-pagination {
  overflow-x: auto;
  white-space: nowrap;
  height: 30px;
}

.swiper-button-next,
.swiper-button-prev,
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
  margin: 0.5rem;
  position: absolute;
  color: black;
  background-color: white;
  border: 1px solid var(--gray-200);
  opacity: 0;
  animation: fadeOut 0.5s;
  animation-fill-mode: forwards;
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev,
.p-carousel:hover .p-carousel-content .p-carousel-prev,
.p-carousel:hover .p-carousel-content .p-carousel-next {
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
}

.swiper-button-next,
.swiper-button-prev {
  width: 2rem !important;
  height: 2rem !important;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-family: "remixicon" !important;
  color: #71717a;
  font-size: 13px !important;
  font-weight: 900;
}

.custom-doctor-dialog .swiper-button-next,
.custom-doctor-dialog .swiper-button-prev,
.custom-doctor-dialog .p-carousel .p-carousel-content .p-carousel-prev,
.custom-doctor-dialog .p-carousel .p-carousel-content .p-carousel-next {
  animation: fadeIn 0.5s !important;
  animation-fill-mode: forwards !important;
}

@media (max-width: 1200px) {
  app-options-header .swiper-button-next,
app-options-header .swiper-button-prev,
app-options-header .p-carousel .p-carousel-content .p-carousel-prev,
app-options-header .p-carousel .p-carousel-content .p-carousel-next {
    animation: fadeIn 0.5s !important;
    animation-fill-mode: forwards !important;
    opacity: 1 !important;
  }
}

.swiper-button-next::after {
  content: "\ea6e" !important;
}

.swiper-button-prev::after {
  content: "\ea64" !important;
}

.swiper-button-disabled,
.swiper-button-prev[hidden],
.swiper-button-next[hidden] {
  display: none !important;
}

.swiper-pagination-handle {
  margin: 0px;
}
.swiper-pagination-handle .swiper-pagination-bullet-first,
.swiper-pagination-handle .swiper-pagination-bullet-last {
  border: 0px !important;
}
.swiper-pagination-handle .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
}
.swiper-pagination-handle .swiper-pagination-bullet-active {
  background: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}
.p-carousel .p-carousel-content .p-carousel-prev {
  z-index: 2;
}

.p-carousel .p-carousel-content .p-carousel-next {
  right: 0;
  z-index: 2;
}

.swiper-button-prev:hover,
.swiper-button-next:hover,
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.swiper-button-prev:focus,
.swiper-button-next:focus,
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #f4f4f5;
  width: 2rem;
  height: 0.5rem;
  transition: none;
  border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #e5e7eb;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #eef2ff;
  color: #312e81;
}

.p-datatable-table {
  border-radius: 5px 5px 0 0;
  border: 1px solid rgba(50, 58, 77, 0.1);
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-datatable-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}

.p-datatable .p-datatable-footer {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
  position: relative;
  text-align: left;
  padding: 0.75rem 1.5rem;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #6b7280;
  background-color: transparent;
  transition: none;
  padding-top: 22px;
  padding-bottom: 17px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #171616;
  /* identical to box height */
  text-transform: uppercase;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #3f3f46;
  background: #fafafa;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #71717a;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #312e81;
  background: #eef2ff;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #3f3f46;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #71717a;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #fafafa;
  color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #f4f4f5;
  color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--primary-color);
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #3f3f46;
  transition: none;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 1rem 1.5rem;
}
@media (max-width: 1200px) {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 10px;
    border: 1px solid #ddd;
  }
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid var(--primary-hover-color);
  outline-offset: 0.15rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #eef2ff;
  color: #312e81;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #eef2ff;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #eef2ff;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #3f3f46;
}

.p-datatable .p-column-resizer-helper {
  background: var(--primary-color);
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #fafafa;
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: rgba(var(--primary-focus-color), 0.1);
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #eef2ff;
  color: #312e81;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #312e81;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #312e81;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.625rem 0.625rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.375rem 0.75rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.75rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.375rem 0.75rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.375rem 0.75rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.5625rem 1.5625rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.9375rem 1.875rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.875rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 0.9375rem 1.875rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 0.9375rem 1.875rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}

.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #f4f4f5;
  border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
  padding: 1.25rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-column-filter-menu-button:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #eef2ff;
  color: #312e81;
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-column-filter-clear-button:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.25rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
  border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #18181b;
  background: #f4f4f5;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 0.75rem;
  border-bottom: 0 none;
  color: #3f3f46;
  background: #fafafa;
  margin: 0;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #f3f4f6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}

.fc.fc-unthemed .fc-view-container th {
  background: #fafafa;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #e5e7eb;
}

.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #e5e7eb;
}

.fc.fc-unthemed .fc-view-container .fc-event {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #ffffff;
}

.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #fafafa;
  border: 1px solid #e5e7eb;
}

.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 13px;
  transition: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-color);
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-color);
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 13px;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 13px;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  color: #3f3f46;
  transition: none;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f4f4f5;
  border-color: #d4d4d8;
  color: #3f3f46;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
  color: #ffffff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  z-index: 1;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #e5e7eb;
}

.fc.fc-theme-standard .fc-view-harness th {
  background: #fafafa;
  border-color: #e5e7eb;
  color: #3f3f46;
}

.fc.fc-theme-standard .fc-view-harness td {
  color: #3f3f46;
  border-color: #e5e7eb;
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #ffffff;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  background: #fafafa;
  color: #3f3f46;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 13px;
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "\e90b";
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  border-top: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #ffffff;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #ffffff;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: #f4f4f5;
  color: #18181b;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #fafafa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 13px;
  transition: none;
  border-radius: 5px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-color);
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-color);
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.6;
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 13px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 13px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  color: #3f3f46;
  transition: none;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f4f4f5;
  border-color: #d4d4d8;
  color: #3f3f46;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
  color: #ffffff;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
  z-index: 1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.fc.fc-theme-standard a {
  color: #3f3f46;
}

.fc.fc-theme-standard .fc-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 700;
}

.p-orderlist .p-orderlist-filter-container {
  padding: 1.25rem;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-orderlist .p-orderlist-list {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  padding: 0.25rem 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.75rem 1rem;
  margin: 0;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
}

.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
  background: #fcfcfc;
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
  background: #f4f4f5;
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.75rem 1rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 0 none;
  color: #3f3f46;
  background: #ffffff;
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #eef2ff;
  color: #312e81;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #6f91ff;
}

.p-organizationchart .p-organizationchart-line-down {
  background: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #e5e7eb;
  border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #e5e7eb;
  border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  padding: 1.25rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-paginator {
  background: #ffffff;
  color: #71717a;
  border: solid #f4f4f5;
  border-width: 0;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 1px solid #d4d4d8;
  color: #71717a;
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-radius: 0;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #f4f4f5;
  border-color: #d4d4d8;
  color: #3f3f46;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 1px solid #d4d4d8;
  color: #71717a;
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 1px solid #d4d4d8;
  color: #71717a;
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-radius: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #eef2ff;
  border-color: #eef2ff;
  color: #312e81;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #f4f4f5;
  border-color: #d4d4d8;
  color: #3f3f46;
}

.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 700;
}

.p-picklist .p-picklist-filter-container {
  padding: 1.25rem;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-picklist .p-picklist-list {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  padding: 0.25rem 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.75rem 1rem;
  margin: 0;
  border: 0 none;
  color: #3f3f46;
  background: transparent;
  transition: none;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #312e81;
  background: #eef2ff;
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.75rem 1rem;
  color: #3f3f46;
}

.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
  background: #fcfcfc;
}

.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
  background: #f4f4f5;
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.75rem 1rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 0 none;
  color: #3f3f46;
  background: #ffffff;
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
}

.p-timeline .p-timeline-event-connector {
  background-color: #e5e7eb;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  padding: 1.25rem;
  border-radius: 5px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 5px;
  transition: none;
  padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #71717a;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #3f3f46;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #eef2ff;
  color: #312e81;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #312e81;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #312e81;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #f4f4f5;
  color: #18181b;
}

.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: #71717a;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #8ba7ff;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 5px;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  color: #3f3f46;
  padding: 0;
  transition: none;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #eef2ff;
  color: #312e81;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #312e81;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}

.p-treetable .p-treetable-footer {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 1.5rem;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #6b7280;
  background: #fafafa;
  transition: none;
}

@media (max-width: 1200px) {
  .p-treetable .p-treetable-thead > tr > th {
    border: 1px solid #ddd;
  }
}

.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #3f3f46;
  background: #fafafa;
}

.p-treetable .p-sortable-column {
  outline-color: var(--primary-hover-color);
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #71717a;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #312e81;
  background: #eef2ff;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #3f3f46;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #71717a;
}

.p-treetable .p-sortable-column.p-highlight {
  background: #fafafa;
  color: var(--primary-color);
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}

.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #3f3f46;
  transition: none;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 1rem 1.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #3f3f46;
}

.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid var(--primary-hover-color);
  outline-offset: -0.15rem;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #eef2ff;
  color: #312e81;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #312e81;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #312e81;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #3f3f46;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #3f3f46;
}

.p-treetable .p-column-resizer-helper {
  background: var(--primary-color);
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #fafafa;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 1.09375rem 1.09375rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.375rem 0.75rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.75rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.375rem 0.75rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.375rem 0.75rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.5625rem 1.5625rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 0.9375rem 1.875rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.875rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 0.9375rem 1.875rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 0.9375rem 1.875rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}

.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  padding: 0;
}

.p-virtualscroller .p-virtualscroller-footer {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
  background: white;
  font-weight: 700;
  border-radius: 5px;
  transition: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.pi-chevron-down::before {
  content: "\ea4e";
  font-family: "remixicon" !important;
  color: #71717a;
  font-size: 13px;
  font-weight: 900;
}

.fa-caret-down:before {
  content: "\ea4d" !important;
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-chevron-right::before,
.pi-angle-right::before {
  content: "\ea6e" !important;
  font-family: "remixicon" !important;
  color: #71717a;
  font-size: 13px;
  font-weight: 900;
}

.p-accordion .pi-chevron-right::before,
.p-accordion .pi-angle-right::before {
  content: "\ea4e" !important;
  font-size: 16px !important;
}
.p-accordion .pi-chevron-down::before {
  content: "\ea78" !important;
  font-size: 16px !important;
}

.pi-chevron-left::before {
  content: "\ea64";
  font-family: "remixicon" !important;
  color: #71717a;
  font-size: 13px;
  font-weight: 900;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  text-decoration: none;
}
.p-accordion .p-accordion-header .p-accordion-toggle-icon {
  position: absolute;
  right: 22px;
}
.p-accordion .p-accordion-header .p-accordion-header-text {
  color: #171616;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.7px;
}
.p-accordion .p-accordion-header .pi-chevron-down::before {
  content: "\ea4e";
  font-family: "remixicon" !important;
  color: #71717a;
  font-size: 13px;
  font-weight: 900;
}
.p-accordion .p-accordion-header .pi-chevron-right::before {
  content: "\f107";
  color: #71717a;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  font-weight: 900;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #f4f4f5;
  border-color: #e5e7eb;
  color: #3f3f46;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: white;
  border-color: #e5e7eb;
  color: #3f3f46;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #e5e7eb;
  background: white;
  color: #3f3f46;
}

.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-card {
  background: #ffffff;
  color: #3f3f46;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.p-card .p-card-body {
  padding: 1.25rem;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #71717a;
}

.p-card .p-card-content {
  padding: 1.25rem 0;
}

.p-card .p-card-footer {
  padding: 1.25rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #e5e7eb;
}

.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px #e5e7eb;
}

.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  border-radius: 5px;
}

.p-fieldset .p-fieldset-legend {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
  background: #fafafa;
  font-weight: 700;
  border-radius: 5px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: none;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1.25rem;
  color: #3f3f46;
  border-radius: 5px;
  transition: none;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #f4f4f5;
  border-color: #e5e7eb;
  color: #18181b;
}

.p-fieldset .p-fieldset-content {
  padding: 1.25rem;
}

.p-panel .p-panel-header {
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  background: #fafafa;
  color: #3f3f46;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-panel .p-panel-header .p-panel-title {
  font-weight: 700;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.75rem 1.25rem;
}

.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top: 0 none;
}

.p-panel .p-panel-footer {
  padding: 0.75rem 1.25rem;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  border-top: 0 none;
}

.p-panel .p-panel-icons-end {
  order: 2;
  margin-left: auto;
}

.p-panel .p-panel-icons-start {
  order: 0;
  margin-right: 0.5rem;
}

.p-panel .p-panel-icons-center {
  order: 2;
  width: 100%;
  text-align: center;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #fafafa;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #e5e7eb;
  background: #ffffff;
  border-radius: 5px;
  color: #3f3f46;
}

.p-splitter .p-splitter-gutter {
  transition: none;
  background: #fafafa;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #e5e7eb;
}

.p-splitter .p-splitter-gutter-resizing {
  background: #e5e7eb;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #e5e7eb;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb transparent;
  background: #ffffff;
  color: #71717a;
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  transition: none;
  margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px transparent;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #71717a;
  color: #71717a;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: var(--primary-color);
  width: 3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px transparent;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  color: #3f3f46;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-toolbar {
  background: #fafafa;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-radius: 5px;
  gap: 0.5rem;
}

.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 1.25rem;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.75rem 1.25rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}

.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}

.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border: 0 none;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #3f3f46;
  padding: 1.5rem;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #18181b;
  border-color: transparent;
  background: #f4f4f5;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #3f3f46;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #3f3f46;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}

.p-overlaypanel .p-overlaypanel-close {
  background: var(--primary-color);
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: none;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: var(--primary-hover-color);
  color: #ffffff;
}

.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #f2f2f2;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.p-sidebar .p-sidebar-header {
  padding: 1.25rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #71717a;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: inherit;
  border-color: transparent;
  background: transparent;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}

.p-sidebar .p-sidebar-content {
  padding: 1.25rem;
}

.p-sidebar .p-sidebar-footer {
  padding: 1.25rem;
}

.p-tooltip .p-tooltip-text {
  background: #3f3f46;
  color: #ffffff;
  padding: 16px 25px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #3f3f46;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #3f3f46;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #3f3f46;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #3f3f46;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #fafafa;
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
  border-bottom: 0 none;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-fileupload .p-progressbar {
  height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1.5rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-color);
}

.p-fileupload-choose:not(.p-disabled):active {
  background: var(--primary-hover-color);
  color: #ffffff;
  border-color: var(--primary-hover-color);
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: none;
  border-radius: 5px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #3f3f46;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #3f3f46;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #71717a;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.p-contextmenu .p-menuitem {
  margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f4f4f5;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-contextmenu .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}

.p-dock .p-dock-item {
  padding: 0.5rem;
}

.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}

.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}

.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next,
.p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}

.p-dock.p-dock-top .p-dock-item-current,
.p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}

.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}

.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next,
.p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}

.p-dock.p-dock-left .p-dock-item-current,
.p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container,
.p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }

  .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
.p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }

  .p-dock.p-dock-left .p-dock-list-container,
.p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }

  .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
.p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }

  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 1rem;
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 5px;
  transition: none;
  user-select: none;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #71717a;
  margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #3f3f46;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #3f3f46;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f4f4f5;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-megamenu .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  width: 12.5rem;
}

.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #3f3f46;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-menu .p-menuitem {
  margin: 0;
}

.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menu .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menubar {
  padding: 0.5rem 0rem;
  background: white;
  color: #3f3f46;
  border: 1px solid transparent;
  border-radius: 5px;
}

.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 5px;
  transition: none;
  user-select: none;
  flex-direction: row-reverse;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #71717a;
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #3f3f46;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f4f4f5;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-menuitem-link .p-submenu-icon .p-menuitem-icon {
  display: none;
}

.p-menubar .p-submenu-list > .p-menuitem-active > p-menubarsub > .p-submenu-list {
  max-height: 44vh;
  overflow-y: auto;
}

.p-menubar .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }

  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border-radius: 50%;
    transition: none;
  }

  .p-menubar .p-menubar-button:hover {
    color: #71717a;
    background: #f4f4f5;
  }

  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--primary-hover-color);
  }

  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 100%;
  }

  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }

  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #3f3f46;
    border-radius: 0;
    transition: none;
    user-select: none;
  }

  .p-menubar .p-menubar-root-list .p-submenu-icon {
    display: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #f4f4f5;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #18181b;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #71717a;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #71717a;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
    position: absolute;
    right: 7px;
  }

  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  color: #3f3f46;
  background: #fafafa;
  font-weight: 700;
  border-radius: 5px;
  transition: none;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #f4f4f5;
  border-color: #e5e7eb;
  color: #3f3f46;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #fafafa;
  border-color: #e5e7eb;
  color: #3f3f46;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #e5e7eb;
  background: #f4f4f5;
  color: #3f3f46;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #3f3f46;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-panelmenu .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #f4f4f5;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #3f3f46;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #71717a;
}

.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #3f3f46;
}

.p-slidemenu .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: none;
  border-radius: 5px;
  background: #ffffff;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #71717a;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #eef2ff;
  color: #312e81;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #3f3f46;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #f3f4f6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-width: 0 0 2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #e5e7eb;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb transparent;
  background: #ffffff;
  color: #71717a;
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  transition: none;
  margin: 0 0 -2px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  text-align: center;
}
@media (max-width: 1200px) {
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    font-weight: 400;
    font-size: 12px;
  }
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px transparent;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #71717a;
  color: #71717a;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: var(--primary-color);
  color: #222222;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  text-align: center;
}
@media (max-width: 1200px) {
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    font-weight: 400;
    font-size: 12px;
  }
}

.p-tabmenu .p-tabmenu-left-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-right-icon {
  margin-left: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: #ffffff;
  color: var(--primary-color);
  width: 3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px transparent;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #3f3f46;
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #71717a;
  margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f4f4f5;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #18181b;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #71717a;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #71717a;
}

.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--primary-hover-color);
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-tieredmenu .p-menuitem {
  margin: 0;
}

.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f4f4f5;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #3f3f46;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #71717a;
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #f3f4f6;
  margin: 0.25rem 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-tieredmenu .p-menuitem-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-inline-message {
  padding: 16px 25px;
  margin: 0;
  border-radius: 5px;
}

.p-inline-message.p-inline-message-info {
  background: #eff6ff;
  border: none;
  border-width: 0;
  color: #2563eb;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #2563eb;
}

.p-inline-message.p-inline-message-success {
  background: #ecfdf5;
  border: none;
  border-width: 0;
  color: #059669;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #059669;
}

.p-inline-message.p-inline-message-warn {
  background: #fef3c7;
  border: none;
  border-width: 0;
  color: #d97706;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #d97706;
}

.p-inline-message.p-inline-message-error {
  background: #fef3c7;
  border: none;
  border-width: 0;
  color: #dc2626;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #dc2626;
}

.p-inline-message .p-inline-message-icon {
  font-size: 13px;
  margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
  font-size: 13px;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 5px;
}

.p-message .p-message-wrapper {
  padding: 1.25rem 1.75rem;
}

.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: none;
}

.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-message.p-message-info {
  background: #eff6ff;
  border: none;
  border-width: 0 0 0 6px;
  color: #2563eb;
}

.p-message.p-message-info .p-message-icon {
  color: #2563eb;
}

.p-message.p-message-info .p-message-close {
  color: #2563eb;
}

.p-message.p-message-success {
  background: #ecfdf5;
  border: none;
  border-width: 0 0 0 6px;
  color: #059669;
}

.p-message.p-message-success .p-message-icon {
  color: #059669;
}

.p-message.p-message-success .p-message-close {
  color: #059669;
}

.p-message.p-message-warn {
  background: #fef3c7;
  border: none;
  border-width: 0 0 0 6px;
  color: #d97706;
}

.p-message.p-message-warn .p-message-icon {
  color: #d97706;
}

.p-message.p-message-warn .p-message-close {
  color: #d97706;
}

.p-message.p-message-error {
  background: #fef3c7;
  border: none;
  border-width: 0 0 0 6px;
  color: #dc2626;
}

.p-message.p-message-error .p-message-icon {
  color: #dc2626;
}

.p-message.p-message-error .p-message-close {
  color: #dc2626;
}

.p-message .p-message-text {
  font-size: 13px;
  font-weight: 500;
}

.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.p-message .p-message-summary {
  font-weight: 700;
}

.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 1;
}

.p-toast-message-primary {
  background-color: var(--primary-color);
  color: white;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: none;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #eff6ff;
  border: none;
  border-width: 0 0 0 6px;
  color: #2563eb;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #2563eb;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #ecfdf5;
  border: none;
  border-width: 0 0 0 6px;
  color: #059669;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #059669;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #fef3c7;
  border: none;
  border-width: 0 0 0 6px;
  color: #d97706;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #d97706;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #de5959;
  border: none;
  border-width: 0 0 0 6px;
  color: white;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: white;
  font-size: 20px;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon::before,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close::before {
  content: "\eca0";
  font-family: "remixicon" !important;
  font-style: normal;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close::before {
  content: "\eb96";
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #fafafa;
  width: 4rem;
  height: 4rem;
  transition: none;
  border-radius: 50%;
}

.p-galleria .p-galleria-close .p-galleria-close-icon::before {
  content: "\eb99";
  font-family: "remixicon" !important;
  color: #71717a;
  font-size: 13px;
  font-weight: 900;
}

.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fafafa;
}

.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #fafafa;
  width: 4rem;
  height: 4rem;
  transition: none;
  border-radius: 5px;
  margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fafafa;
}

.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #fafafa;
  padding: 1rem;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #f4f4f5;
  width: 1rem;
  height: 1rem;
  transition: none;
  border-radius: 50%;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #e5e7eb;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #eef2ff;
  color: #312e81;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #eef2ff;
  color: #312e81;
}

.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #fafafa;
  width: 2rem;
  height: 2rem;
  transition: none;
  border-radius: 50%;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fafafa;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
  border-radius: 0 !important;
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: none;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: none;
  margin-right: 0.5rem;
}

.p-image-action.p-link:last-child {
  margin-right: 0;
}

.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}

.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: #e5e7eb;
  border-radius: 5px;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  background-color: #64748b;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #22c55e;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #3b82f6;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #f59e0b;
  color: #ffffff;
}

.p-badge.p-badge-danger {
  background-color: #ef4444;
  color: #ffffff;
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-chip {
  background-color: #e5e7eb;
  color: #3f3f46;
  border-radius: 16px;
  padding: 0 0.75rem;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}

.p-chip img {
  width: 2.25rem;
  height: 2.25rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  border-radius: 5px;
  transition: none;
}

.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-inplace .p-inplace-display {
  padding: 16px 25px;
  border-radius: 5px;
  transition: none;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #f4f4f5;
  color: #18181b;
}

.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--primary-hover-color);
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #e5e7eb;
  border-radius: 5px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #222222;
}

.p-progressbar .p-progressbar-label {
  color: #ffffff;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: none;
}

.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}

.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}

.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #fafafa;
}

.p-skeleton {
  background-color: #f4f4f5;
  border-radius: 5px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: var(--primary-color);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 5px;
}

.p-tag.p-tag-success {
  background-color: #22c55e;
  color: #ffffff;
}

.p-tag.p-tag-info {
  background-color: #3b82f6;
  color: #ffffff;
}

.p-tag.p-tag-warning {
  background-color: #f59e0b;
  color: #ffffff;
}

.p-tag.p-tag-danger {
  background-color: #ef4444;
  color: #ffffff;
}

.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: #3f3f46;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
}

.p-terminal .p-terminal-input {
  font-size: 13px;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* Customizations to the designer theme should be defined here */
.p-inputtext,
.p-togglebutton,
.p-selectbutton,
.p-inputgroup {
  box-shadow: none;
}

.p-inputgroup .p-inputtext,
.p-inputgroup .p-togglebutton,
.p-inputgroup .p-selectbutton {
  box-shadow: none;
}

.p-inputtext.p-invalid.p-component:enabled:focus {
  box-shadow: 0 0 0 1px #de5959;
}

.p-highlight {
  font-weight: 600;
}

.p-button-label {
  font-weight: 300;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 2px var(--primary-hover-color);
}

.p-paginator .p-paginator-pages .p-paginator-page {
  margin-left: -1px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-color: var(--primary-color);
  margin-right: 1px;
}

.p-paginator .p-paginator-current {
  border: 0 none;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--primary-hover-color), 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-button.p-button-secondary:enabled:focus {
  box-shadow: none;
}

.p-button.p-button-success:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #22c55e, 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-button.p-button-info:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3b82f6, 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-button.p-button-warning:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f59e0b, 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-button.p-button-help:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #a855f7, 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-button.p-button-danger:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #ef4444, 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-checkbox .pi-check::before {
  content: "\eb7a";
  font-family: "remixicon" !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
}

.p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--primary-hover-color), 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--primary-color);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--primary-color);
}

.w-100,
.wb-100 {
  width: 100%;
  text-align: center;
}
.w-100.p-inputtext,
.wb-100.p-inputtext {
  text-align: left;
}

.w-100-calendar {
  width: 100%;
}
.w-100-calendar span {
  width: 100%;
}

.wb-100 {
  display: block !important;
  letter-spacing: -0.02em;
}
@media (max-width: 1200px) {
  .wb-100 {
    margin-bottom: 20px;
  }
}

.wb-drop .p-dropdown {
  width: 100%;
  margin-top: 6px;
}
.wb-drop .p-dropdown .p-dropdown-label {
  text-align: left;
}
.wb-drop .p-dropdown .p-dropdown-items {
  text-align: left;
}

.title-core,
.title-only {
  display: block;
  width: 100%;
  padding-top: 32px;
  margin-bottom: 32px;
  color: #58585B;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
}
@media (max-width: 1200px) {
  .title-core,
.title-only {
    font-size: 32px;
    margin-bottom: 36px;
  }
}
.title-core:before,
.title-only:before {
  content: " ";
  width: 102px;
  height: 0;
  border: 2px solid #000000;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
}

h2.title-only {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: -0.04em;
  margin: 0;
  padding: 0;
  text-align: left;
}
@media (max-width: 1200px) {
  h2.title-only {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.88px;
  }
}

.title-only::before {
  display: none;
  width: 0;
}

.page-body {
  min-height: 500px;
}
@media (max-width: 1200px) {
  .page-body > .container {
    padding: 0 16px;
  }
}

.m-0 {
  margin: 0;
  padding: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.drown-down-sticky {
  position: sticky;
  top: 77px;
  background: white;
  z-index: 12;
}
@media (max-width: 1200px) {
  .drown-down-sticky {
    top: 67px;
  }
}

.general-form {
  margin-bottom: 20px;
  font-family: var(--font-family);
}
.general-form .source {
  position: relative;
  top: -6px;
}
@media (max-width: 1200px) {
  .general-form .source {
    top: -2px;
  }
}
.general-form .required::after {
  content: "*";
  color: #f14831;
}
.general-form .iti {
  border: 1px solid transparent;
  max-width: 100%;
  width: 100%;
}
.general-form .iti input {
  max-width: 100%;
  padding: 16px 25px 16px 57px;
  font-size: 16px;
}
.general-form .iti .custom__phone-lib_2 {
  border-radius: 5px;
  border: 1px solid #d4d4d8;
}
.general-form .select-form {
  width: 100%;
  font-size: 18px;
  font-family: var(--font-family);
}
.general-form .select-form option {
  padding: 10px;
}
.general-form label {
  width: 100%;
  font-family: var(--font-family) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.05em;
}
.general-form div label {
  font-size: 12px;
  position: absolute;
  top: -23px;
}
.general-form .control__error {
  margin: 0 !important;
  padding: 0 !important;
}
.general-form .checkbox input {
  position: relative;
  top: 2px;
}
.general-form .checkbox label {
  display: inline;
  position: inherit;
  font-size: 14px;
}
.general-form .p-inputtextarea {
  width: 100%;
  max-width: 100%;
}
.general-form input {
  width: 100%;
}
.general-form .p-inputtext {
  padding: 16px 25px;
}
@media (max-width: 1200px) {
  .general-form .p-inputtext {
    padding-left: 15px;
  }
}
.general-form .p-dropdown {
  width: 100%;
}
.general-form .p-dropdown .p-inputtext {
  padding: 14px 25px;
  width: 100%;
}
.general-form .checkbox-wrapper {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 27px 20px;
}
.general-form .checkbox {
  display: block;
  margin-right: 24px;
}
.general-form .checkbox label {
  margin-left: 8px;
}
.general-form .checkbox label,
.general-form .checkbox input {
  display: inline;
  color: black;
}
.general-form .checkbox input {
  width: auto;
}

@media (max-width: 1200px) {
  .expanded-guaranteed,
.bordered-hr-form {
    background: white;
    border-top: 8px solid #EBEBEB;
    border-bottom: 8px solid #EBEBEB;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    width: calc(100% + 40px);
    left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 13px;
  }
}

@media (max-width: 1200px) {
  .bordered-hr-form {
    border-top: 0px;
  }
}

.step-center .p-tabmenu-nav {
  align-items: center;
  justify-content: center;
}

.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.02em;
  margin-bottom: 28px;
}
@media (max-width: 1200px) {
  .card-title {
    font-size: 20px;
  }
}

.card-wrapper-list {
  border: 1px solid rgba(217, 217, 217, 0.5);
  border-radius: 10px;
}
.card-wrapper-list .list {
  padding: 16px 40px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
}
.card-wrapper-list .list ol {
  list-style: none;
  padding: 0;
  margin: 2px;
}
.card-wrapper-list .list h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #171616;
  margin: 0;
}
.card-wrapper-list .list:first-child {
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  border-top: 1px solid transparent;
}
.card-wrapper-list .list:last-child {
  border-top: 1px solid transparent;
}
.card-wrapper-list .list p {
  margin: 0;
  word-break: break-all;
}

.photo-responsive {
  width: 100%;
}
.photo-responsive img {
  width: 100%;
  height: auto;
}

.card-general-wrapper {
  padding: 70px;
  text-align: left;
  background: #FFFFFF;
  border: 1px solid rgba(217, 217, 217, 0.3);
  box-shadow: 0 32px 72px #F1F1F1;
  border-radius: 16px;
}
.card-general-wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #171616;
  text-align: left;
}
.card-general-wrapper h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}
.card-general-wrapper ul {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #6A6565;
}
@media (max-width: 1200px) {
  .card-general-wrapper {
    padding: 20px;
  }
}

.new-nav-wrapper {
  position: sticky;
  top: 50px;
}
.new-nav-wrapper nav {
  border-top: none;
  background-color: #00c1f0;
  height: 55px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
.new-nav-wrapper nav a {
  cursor: pointer;
}

.card-general-wrapper-outline {
  padding: 70px;
  text-align: left;
  background: transparent;
  border: 1px solid rgba(217, 217, 217, 0.3);
  border-radius: 16px;
}
.card-general-wrapper-outline h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
  font-family: var(--font-family);
  color: #292929;
}

.new-nav-sticky {
  position: fixed;
  width: 100%;
  left: 0;
  max-width: 1110px;
  margin: 0 auto;
  right: 0;
  top: 68px;
  z-index: 1094;
}

.general-title-page h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: #222222;
}
@media (max-width: 1200px) {
  .general-title-page .back-title {
    font-size: 16px;
    text-align: center;
  }
  .general-title-page .back-title span {
    font-size: 24px;
    float: left;
    cursor: pointer;
    position: absolute;
    left: 20px;
  }
}
.general-title-page .back-title span {
  font-size: 24px;
  float: left;
  cursor: pointer;
  position: relative;
  left: -10px;
}

.general-list-content h1 {
  color: #101010;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.6px;
  margin-top: 46px;
  margin-bottom: 46px;
}
@media (max-width: 1200px) {
  .general-list-content h1 {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.general-list-content a,
.general-list-content .no-link {
  color: #6A6969;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  text-decoration: underline;
  margin-bottom: 20px;
}

.general-widget-content {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #6A6969;
  padding-top: 55px;
}
@media (max-width: 1200px) {
  .general-widget-content {
    padding-top: 27px;
  }
}
.general-widget-content h2 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #292929;
  margin: 0;
}
.general-widget-content h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #292929;
  margin-top: 20px;
  margin-bottom: 15px;
}
.general-widget-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #6A6969;
}
@media (max-width: 1200px) {
  .general-widget-content p {
    text-align: justify;
  }
}
.general-widget-content a {
  color: var(--primary-color);
  cursor: pointer;
}
.general-widget-content .ul {
  list-style: disc;
  padding-left: 19px;
}
.general-widget-content ul li {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}
.general-widget-content .phone-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  max-width: 340px;
}
.general-widget-content .phone-list li {
  padding-top: 10px;
  padding-bottom: 10px;
}
.general-widget-content img {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 24px;
}
.general-widget-content.widget-frame {
  padding: 24px;
  border: 1px solid rgba(229, 231, 235, 0.5);
  border-radius: 24px;
}

.custom-tab h2 {
  color: #292929;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}
.custom-tab h3 {
  font-size: 18px;
  font-weight: 500;
}
.custom-tab p,
.custom-tab ul > li {
  color: #6A6969;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.custom-tab .p-tabview .p-tabview-panels {
  padding: 0;
}
.custom-tab .title-only {
  color: #171616;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.88px;
}
.custom-tab .p-tabview .p-tabview-nav a {
  color: #222;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.custom-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #222;
  border-color: #222;
  padding-left: 0;
  padding-right: 0;
}
.custom-tab .ui-tabview .p-tabview-nav > li {
  list-style: none;
  float: none;
  position: relative;
  margin: 0 0.125em 1px 0;
  padding: 0;
  white-space: nowrap;
  display: inline-block !important;
}

.general-card {
  border: 1px solid #dddd;
  padding: 20px;
  border-radius: 10px;
}
.general-card .title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.general-connect {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DDD;
  border-radius: 24px;
  padding: 49px 30px;
  min-height: 283px;
  margin-bottom: 24px;
}
.general-connect .icon-wrapper {
  text-align: center;
}
.general-connect .icon-wrapper .icon {
  width: 80px;
  height: 80px;
  display: inline-block;
  padding: 26px;
  border-radius: 100%;
  font-size: 34.5px;
  padding-left: 30px;
}
.general-connect h2 {
  color: #171616;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.88px;
}
.general-connect h2 span {
  color: #171616;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.88px;
}
.general-connect a {
  display: block;
  padding: 4px;
  text-align: center;
  color: rgba(23, 22, 22, 0.7);
  text-align: center;
  font-size: 16px;
}
.general-connect .phone-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(23, 22, 22, 0.7);
  width: 100%;
  display: inline-block !important;
}
.general-connect .phone-list li {
  text-align: center;
  width: 100%;
  flex: 100%;
}

.general-member {
  background: #FFFFFF;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .general-member {
    padding-bottom: 0px;
    margin-bottom: 60px;
  }
}
.general-member .name {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  display: inline-block;
  width: 100%;
}
.general-member .position {
  color: #717171;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.general-member blockquote {
  min-height: 57px;
  font-size: 14px;
  line-height: 20px;
}
.general-member .photo {
  width: 100%;
  min-height: 339.394px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 12px;
}
.general-member .more-info {
  color: #222;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: inline-flex;
  cursor: pointer;
}
.general-member .more-info .linkedin-link {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.general-member .more-info i {
  display: inline;
}
@keyframes fadeIn {
  0% {
    height: 0;
  }
  100% {
    height: 80%;
  }
}
.general-member .more-info-wrapper {
  color: #222;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  background: #ffffffe6;
  height: 80%;
  overflow-y: auto;
  max-height: 390px;
  padding: 10px;
  margin-top: 30px;
  animation: fadeIn 1s;
  bottom: 28px;
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .general-member .more-info-wrapper {
    height: 80%;
    overflow-y: auto;
    max-height: 390px;
    padding: 10px;
    margin-top: 30px;
    animation: fadeIn 1s;
    bottom: 0;
    overflow-x: hidden;
  }
}
.general-member .linkedin-link {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  margin-top: 13px;
  display: inline-block;
}
.general-member dl dt {
  display: block;
  text-align: left;
  font-style: normal;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.04em;
  text-align: left;
  margin-top: 10px;
  color: #717171;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  margin-top: 22px;
}
.general-member dl dd {
  display: block;
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #717171;
}

.general-margin {
  margin-bottom: 131px;
}
@media (max-width: 1200px) {
  .general-margin {
    margin-bottom: 81px;
  }
}

.general-page-wrapper h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 1200px) {
  .general-page-wrapper h1 {
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
}
.general-page-wrapper p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}
.general-page-wrapper ul,
.general-page-wrapper ol {
  list-style: disc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  padding: 18px;
}
.general-page-wrapper ul li,
.general-page-wrapper ol li {
  text-align: justify;
}
.general-page-wrapper .static-content,
.general-page-wrapper .dynamic-content,
.general-page-wrapper .cms-content {
  padding: 0px;
  border: 0px solid rgba(229, 231, 235, 0.5);
  border-radius: 0px;
}
.general-page-wrapper .static-content div > em,
.general-page-wrapper .dynamic-content div > em,
.general-page-wrapper .cms-content div > em {
  margin-bottom: 10px;
}
.general-page-wrapper .static-content h2,
.general-page-wrapper .static-content h4,
.general-page-wrapper .static-content h3,
.general-page-wrapper .dynamic-content h2,
.general-page-wrapper .dynamic-content h4,
.general-page-wrapper .dynamic-content h3,
.general-page-wrapper .cms-content h2,
.general-page-wrapper .cms-content h4,
.general-page-wrapper .cms-content h3 {
  margin: 1rem 0px;
}
.general-page-wrapper .static-content .location-items,
.general-page-wrapper .dynamic-content .location-items,
.general-page-wrapper .cms-content .location-items {
  font-weight: 500;
  font-size: 18px;
  line-height: 200%;
  text-decoration-line: underline;
  text-decoration-color: #6a6969;
  color: #6A6969;
}
.general-page-wrapper .static-content ul,
.general-page-wrapper .static-content ol,
.general-page-wrapper .dynamic-content ul,
.general-page-wrapper .dynamic-content ol,
.general-page-wrapper .cms-content ul,
.general-page-wrapper .cms-content ol {
  list-style: disc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  padding: 18px;
}
.general-page-wrapper .static-content ul li,
.general-page-wrapper .static-content ol li,
.general-page-wrapper .dynamic-content ul li,
.general-page-wrapper .dynamic-content ol li,
.general-page-wrapper .cms-content ul li,
.general-page-wrapper .cms-content ol li {
  text-align: justify;
}
.general-page-wrapper .static-content p,
.general-page-wrapper .dynamic-content p,
.general-page-wrapper .cms-content p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

/** Added Icons here since not working on Styles on Live **/
.fa-cust {
  height: 35px;
  width: 35px;
  background-size: 33px;
  background-repeat: no-repeat;
  display: inline-block;
  background-position: 1px 2px;
}
.fa-cust.fa-all-for-number {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/all-on-4.svg?format=webp");
}
.fa-cust.fa-all-for-six {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/all-on-6.svg?format=webp");
}
.fa-cust.fa-dental-crowns {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/crowns.svg?format=webp");
}
.fa-cust.fa-dental-implants {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/dental-implant.svg?format=webp");
}
.fa-cust.fa-dentures {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/dentures.svg?format=webp");
}
.fa-cust.fa-root-canal {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/root-canal.svg?format=webp");
}
.fa-cust.fa-teeth-cleaning {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/whitening.svg?format=webp");
}
.fa-cust.fa-veneers {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/veneers.svg?format=webp");
}
.fa-cust.fa-cleaning {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/cleaning.svg?format=webp");
}
.fa-cust.fa-snap-on-dentures {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/snap-on-dentures.svg?format=webp");
}
.fa-cust.fa-dental-fillings {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/filling.svg?format=webp");
}
.fa-cust.fa-sinus {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/sinus-lifting.svg?format=webp");
}
.fa-cust.fa-z-implants {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/zygomatic-implants.svg?format=webp");
}
.fa-cust.fa-deep-cleaning {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/deep-cleaning.svg?format=webp");
}
.fa-cust.fa-tooth-extract {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/extraction.svg?format=webp");
}
.fa-cust.fa-clear-braces {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/clear-braces.svg?format=webp");
}
.fa-cust.fa-bone-graft {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/bone-graft.svg?format=webp");
}
.fa-cust.fa-ct-scan {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/ct-scan.svg?format=webp");
}
.fa-cust.fa-x-rays {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/x-ray.svg?format=webp");
}
.fa-cust.fa-teeth-gas {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/dd/gas-sedation.svg?format=webp");
}
.fa-cust.fa-medical-body {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/body.png?format=webp");
}
.fa-cust.fa-medical-breast {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/breast.png?format=webp");
}
.fa-cust.fa-medical-liposuction {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/liposuction.png?format=webp");
}
.fa-cust.fa-ivf {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/fertility.png?format=webp");
}
.fa-cust.fa-hair-transplant {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/hair-transplant.png?format=webp");
}
.fa-cust.fa-medical-nose-job {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/nose.png?format=webp");
}
.fa-cust.fa-medical-sex-reassignment {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/sex-reassignment.png?format=webp");
}
.fa-cust.fa-medical-face {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/face.png?format=webp");
}
.fa-cust.fa-medical-butt {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/butt.png?format=webp");
}
.fa-cust.fa-medical-eyes {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/eyes.png?format=webp");
}
.fa-cust.fa-medical-fertility {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/fertility.png?format=webp");
}
.fa-cust.fa-medical-orthopedics {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/orthopedics.png?format=webp");
}
.fa-cust.fa-medical-weight-loss {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/weight-loss.png?format=webp");
}
.fa-cust.fa-medical-rejuvenation {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/skin-rejuvenation.png?format=webp");
}
.fa-cust.fa-medical-men {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/men.png?format=webp");
}
.fa-cust.fa-medical-anti-aging {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/Anti-aging.png?format=webp");
}
.fa-cust.fa-medical-heart {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/heart.png?format=webp");
}
.fa-cust.fa-medical-health-wellness {
  background-image: url("https://static.medicaldepartures.com/procedure-icons/md/health.png?format=webp");
}

.page-title {
  color: #222;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 1200px) {
  .page-title {
    color: #222;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
}

@media (max-width: 1200px) {
  .locations .page-title {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.footer-hr {
  border-top: 1px solid #DDD;
  max-width: 70%;
  margin: 0 auto;
  position: relative;
  margin-top: 38px;
  margin-bottom: 85px;
}

/** Remove Chat Temporary **/
.grecaptcha-badge,
.environment-badge {
  display: none !important;
}

.zsiq_floatmain #zsiq_float {
  display: inline-block !important;
}

/** Custom File Upload **/
.custom-upload-design {
  position: relative;
}
.custom-upload-design .progress-bar {
  position: relative;
  display: block;
  width: 300px;
  margin: 0 auto;
}
.custom-upload-design .p-fileupload-content {
  padding: 57px 38px;
  min-height: 400px;
}
.custom-upload-design .p-fileupload-content p-messages {
  display: none;
}
.custom-upload-design .p-fileupload-content .upload-info,
.custom-upload-design .p-fileupload-content .upload-info-hasfile {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
}
.custom-upload-design .p-fileupload-content .upload-info .icon,
.custom-upload-design .p-fileupload-content .upload-info-hasfile .icon {
  width: 48px;
  height: 44.36px;
}
.custom-upload-design .p-fileupload-content .upload-info .pi-file,
.custom-upload-design .p-fileupload-content .upload-info-hasfile .pi-file {
  font-size: 30px;
}
.custom-upload-design .p-fileupload-content .upload-info-hasfile .pi-file {
  margin-top: 40px;
}
.custom-upload-design .p-fileupload-content .upload-info-hasfile .btn {
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  bottom: 32px;
}
.custom-upload-design .p-fileupload-content .upload-info-hasfile .btn i {
  width: 20px;
  height: 20px;
}
.custom-upload-design .p-fileupload-content .upload-info-hasfile .plr-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.custom-upload-design .p-fileupload-content .file-info {
  position: absolute;
  bottom: 40px;
  text-align: center;
  left: 0;
  right: 0;
}
.custom-upload-design.hide-choose .p-fileupload-buttonbar,
.custom-upload-design.hide-choose .p-fileupload-files,
.custom-upload-design.hide-choose .p-progressbar {
  display: none !important;
}
.custom-upload-design.loading .p-fileupload-buttonbar,
.custom-upload-design.loading .p-fileupload-files {
  display: none !important;
}
.custom-upload-design.loading .p-progressbar {
  display: block !important;
}
.custom-upload-design.loading .p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
}
.custom-upload-design .p-fileupload .p-fileupload-buttonbar {
  position: absolute;
  z-index: 1;
  display: inline-block;
  padding: 0;
  width: 100%;
  width: 283px;
  margin: auto;
  background-color: transparent;
  border-color: transparent;
  left: 0;
  right: 0;
  bottom: 132px;
  margin-bottom: 12px;
}
.custom-upload-design .p-fileupload .p-fileupload-buttonbar span.p-button {
  margin: 0;
  width: 100%;
}
.custom-upload-design .p-fileupload .p-fileupload-content {
  min-height: 400px;
  border: 1.3px dashed rgba(50, 58, 77, 0.4);
  gap: 35px;
}
.custom-upload-design .p-fileupload .p-fileupload-content.p-fileupload-highlight {
  position: relative;
}
.custom-upload-design .p-fileupload .p-fileupload-content.p-fileupload-highlight:before {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffffbf;
  z-index: 1;
}

.pToastPosition {
  bottom: 91px !important;
}

p-scrollpanel.scroll-left-wrapper .scroll-ltr,
p-scrollpanel.scroll-left-wrapper-main .scroll-ltr {
  direction: ltr;
}
p-scrollpanel.scroll-left-wrapper .p-scrollpanel-wrapper,
p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-wrapper {
  max-height: 650px;
  display: contents;
}
p-scrollpanel.scroll-left-wrapper .p-scrollpanel-content,
p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-content {
  direction: rtl;
  padding-left: 12px;
  max-height: inherit;
}
p-scrollpanel.scroll-left-wrapper .p-scrollpanel-content::-webkit-scrollbar,
p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-content::-webkit-scrollbar {
  width: 5px;
  scrollbar-color: #ffffff #F0F0F0;
}
p-scrollpanel.scroll-left-wrapper .p-scrollpanel-content::-webkit-scrollbar-track,
p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-content::-webkit-scrollbar-track {
  background: #ffffff;
}
p-scrollpanel.scroll-left-wrapper .p-scrollpanel-content::-webkit-scrollbar-thumb,
p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-content::-webkit-scrollbar-thumb {
  background-color: #222222;
  border-radius: 10px;
  border: 1px solid #ffffff;
  padding-right: 10px;
}

p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-wrapper {
  max-height: 200px;
  display: contents;
}
p-scrollpanel.scroll-left-wrapper-main .p-scrollpanel-content {
  max-height: inherit;
}

.general-book-clinic-wrapper {
  margin-top: 61px;
  max-width: 1044px;
  margin: 0 auto;
  margin-top: 60px;
}
.general-book-clinic-wrapper .cs-space {
  margin-top: 31px;
  margin-bottom: 9px;
}
.general-book-clinic-wrapper .general-form label {
  text-transform: capitalize;
}
.general-book-clinic-wrapper h2 {
  margin-bottom: 20px;
}
.general-book-clinic-wrapper .cs-appointment {
  margin-top: 20px;
}
.general-book-clinic-wrapper .custom-hr {
  background: #d9d9d9;
  border-color: #d9d9d9;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 12px;
}
@media (max-width: 1200px) {
  .general-book-clinic-wrapper {
    margin-top: 0px;
  }
  .general-book-clinic-wrapper .custom-hr {
    height: 8px;
    background: #EBEBEB;
    width: calc(100% + 40px);
    left: -20px;
    position: relative;
  }
}

.general-partner-clinic {
  margin-top: 50px;
}
.general-partner-clinic .step-partner-wrapper {
  margin: 0;
  padding: 0;
  margin-bottom: 84px;
}
.general-partner-clinic .step-partner-wrapper .step-subtitle {
  color: #717171;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 166.667% */
}
.general-partner-clinic .step-partner-wrapper .title-partner-fixed {
  position: fixed;
  top: 50%;
  left: 0;
  margin-top: -100px;
  max-width: 1760px;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
}
.general-partner-clinic .step-partner-wrapper .step-title {
  color: #222;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  margin-bottom: 21px;
}
.general-partner-clinic .step-partner-wrapper .feature-list-wrapper .feature-title {
  color: #222;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 118.182% */
  margin-bottom: 8px;
}
.general-partner-clinic .step-partner-wrapper .feature-list-wrapper .feature-subtitle {
  color: #717171;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 133.333% */
}
@media (max-width: 1200px) {
  .general-partner-clinic .step-partner-wrapper .step-title {
    color: #222;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .general-partner-clinic .step-partner-wrapper .step-subtitle {
    color: #717171;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  .general-partner-clinic .step-partner-wrapper .feature-list-wrapper {
    margin-top: 22px;
  }
  .general-partner-clinic .step-partner-wrapper .feature-list-wrapper .feature-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .general-partner-clinic .step-partner-wrapper .feature-list-wrapper .feature-subtitle {
    color: #717171;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
.general-partner-clinic .clinic-partner-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 6px solid #DDD;
  padding-top: 16px;
  padding-bottom: 12px;
  z-index: 1;
}
.general-partner-clinic .clinic-partner-footer .min-151 {
  min-width: 151px;
}
.general-partner-clinic .clinic-partner-footer .text-center-button {
  text-align: center;
  display: inline;
}
@media (max-width: 1200px) {
  .general-partner-clinic .clinic-partner-footer .wb-100-m {
    width: 100%;
    display: block;
  }
}
.general-partner-clinic .clinic-partner-footer .progressBar {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
}
.general-partner-clinic .clinic-partner-footer .custom-text-btn {
  color: #222;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
  text-decoration-line: underline;
  padding: 13px 1rem;
  font-size: 16px;
  transition: none;
  border-radius: 5px;
  font-weight: 300;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  body .zsiq_floatmain-hidden {
    visibility: hidden !important;
  }
}
@media only screen and (min-width: 100px) and (max-width: 555px) {
  .zls-sptwndw.siqembed.siqtrans.zsiq-mobhgt.zsiq-newtheme.mobile-device.siq_rht.zsiq_size2.siqanim {
    height: 73% !important;
  }
}
.finance blockquote {
  padding: 8px !important;
  text-align: left;
  margin: 5px !important;
  background: url(https://img.medicaldepartures.com/quote-left2.png) top left no-repeat, url(https://img.medicaldepartures.com/quote-right2.png) bottom right no-repeat;
  text-indent: 27px;
}