// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin mobile {
  @media (max-width: $screen-xl-min) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

.desktop-only {
  display: block;
}
td.desktop-only, th.desktop-only {
  display: table-cell;
}
.mobile-only {
  display: none;
}
@include mobile {
  .desktop-only, td.desktop-only, th.desktop-only {
    display: none;
  }
  td.mobile-only, th.mobile-only {
    display: table-cell;
  }
  span.mobile-only {
    display: inline-block;
  }
  .mobile-only {
    display: block;
  }
}
