:root {
    --primary-color: #B50063;
    --primary-border-color: #B50063;
    --primary-color-text: #ffffff;

    --primary-hover-color: #960052;
    --primary-hover-border-color: #960052;

    --icon-bg: #FFE1E8;
    --icon-wrapper-bg: #FFF6F6;
}
